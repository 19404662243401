.sidebar {
  display: flex;
  height: 100%;
  width: 100%;

  .sidebar__company-selection-bar {
    width: 72px;
    padding: 14px;

    .sidebar__company-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
    }
  }

  .channel-list-bar {
    display: flex;
    width: 100%;
    flex-direction: column;

    .channel-list-bar__header {
      padding-inline: 16px;
      height: var(--header-height);
    }
  }
}

.side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 199;
}
