html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.app__wrapper {
  display: flex;
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.33) 0 1px 4px 0;

  // TODO: fix adaptive sidebar
  // @media only screen and (max-width: 930px) {
  //   .sidebar {
  //     width: 100%;
  //     position: absolute;
  //     left: -100%;
  //   }
  // }

  @media only screen and (max-width: 768px) {
    .str-chat__thread,
    .pinned-messages__container {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;
      width: 100vw;
      max-width: 100%;
      background: white;
    }
  }
}

.channel__container {
  height: 100%;
  width: 100%;
  min-width: 0;
  flex: 1;
}

.str-chat__thread,
.pinned-messages__container {
  width: 30%;
  min-width: 300px;
}

.channel-empty__container {
  .channel-empty__avatars {
    --str-chat__avatar-size: 72px;
    --str-chat__avatar-background-color: var(--str-chat__primary-color);
  }
}

.channel-preview__item {
  &.single .str-chat__avatar {
    --str-chat__avatar-size: 24px;
  }
  &.multi .str-chat__avatar {
    --str-chat__avatar-size: 18px;
  }
}

.channel-search__result-container {
  --str-chat__avatar-size: 24px;
}

.str-chat__message {
  --str-chat__avatar-size: 34px;
}
